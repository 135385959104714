export default function Logo({ size = 1, ...rest }) {
    return (
        <div {...rest}>
            <svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 283.46 283.46"
                style={{ width: 40 * size, height: 40 * size }}
            >
                <polygon points="107.44 201.05 73.19 260.38 141.69 260.38 107.44 201.05" style={{fill: '#3187bb', stroke: '#3187bb', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="73.19 141.73 38.94 201.05 107.44 201.05 73.19 141.73" style={{fill: '#2c89c0', stroke: '#2c89c0', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="210.27 23.09 176.02 82.41 244.52 82.41 210.27 23.09" style={{fill: '#e63919', stroke: '#e63919', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="73.19 23.09 38.94 82.41 107.44 82.41 73.19 23.09" style={{fill: '#76697b', stroke: '#76697b', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="141.69 23.09 107.44 82.41 175.94 82.41 141.69 23.09" style={{fill: '#9c595a', stroke: '#9c595a', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="38.94 82.41 4.69 141.73 73.19 141.73 38.94 82.41" style={{fill: '#51799d', stroke: '#51799d', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="244.44 82.41 210.19 141.73 278.69 141.73 244.44 82.41" style={{fill: '#b84d3f', stroke: '#b84d3f', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="175.94 201.05 141.69 260.38 210.19 260.38 175.94 201.05" style={{fill: '#5d7592', stroke: '#5d7592', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="210.19 141.73 175.94 201.05 244.44 201.05 210.19 141.73" style={{fill: '#8c606a', stroke: '#8c606a', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="141.69 260.38 175.94 201.05 107.44 201.05 141.69 260.38" style={{fill: '#477ea8', stroke: '#477ea8', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="210.19 260.38 244.44 201.05 175.94 201.05 210.19 260.38" style={{fill: '#746b7e', stroke: '#746b7e', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="244.52 201.05 278.77 141.73 210.27 141.73 244.52 201.05" style={{fill: '#a35655', stroke: '#a35655', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="73.19 260.38 107.44 201.05 38.94 201.05 73.19 260.38" style={{fill: '#1d90d0', stroke: '#1d90d0', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="38.94 201.05 73.19 141.73 4.69 141.73 38.94 201.05" style={{fill: '#3e81af', stroke: '#3e81af', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="73.19 141.73 107.44 82.41 38.94 82.41 73.19 141.73" style={{fill: '#64728c', stroke: '#64728c', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="210.27 141.73 244.52 82.41 176.02 82.41 210.27 141.73" style={{fill: '#d0432b', stroke: '#d0432b', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="107.44 82.41 141.69 23.09 73.19 23.09 107.44 82.41" style={{fill: '#8a616c', stroke: '#8a616c', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
                <polygon points="176.02 82.41 210.27 23.09 141.77 23.09 176.02 82.41" style={{fill: '#af514a', stroke: '#af514a', strokeWidth: 1, strokeLinecap: 'round', strokeDasharray: 1000}} />
            </svg>
        </div>
    )
}