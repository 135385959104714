import { Theme, useTheme } from "remix-themes"

export default function CaberoLogo({ size = 1, ...rest }) {
    const [theme] = useTheme()

    const fontColor = theme === 'light' ? 'hsl(222.2 84% 4.9%)' : 'hsl(222.22 4.9% 84%)'

    return (
        <div {...rest}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.5 64.94" preserveAspectRatio="none" style={{ width: 159.5 * size, height: 64.94 * size }}>
                <g>
                    <g>
                        <path style={{ fill: fontColor }} d="M23.8,43.44c0-6.7-3.5-11.9-11.3-11.9-9.9,0-12.5,9-12.5,16.7,0,8.4,2.7,16.7,12.5,16.7,7.7,0,11.3-5.4,11.3-12.4H16.2c-.2,4-.5,6.5-3.5,6.5-3.8,0-3.9-4.1-3.9-10.7,0-6,.5-10.8,3.9-10.8,3,0,3.5,3.8,3.5,5.9Z" />
                        <path style={{ fill: fontColor }} d="M26.6,64.34h6.8l1.4-6.5h7.5l1.3,6.5h8.2l-6.9-32.2H34.3ZM38.6,39h0l2.5,13.1H35.9L38.6,39Z" />
                        <path style={{ fill: fontColor }} d="M55.6,64.34H68.7c6.4,0,10.6-3.4,10.6-8.9,0-4.2-2.5-6.9-6.7-8v-.1a7.71,7.71,0,0,0,6.1-7.6c0-4.4-3.6-7.7-9.4-7.7H55.7l-.1,32.3Zm8.1-14h3a3.84,3.84,0,0,1,4.1,3.3v.7c0,3.3-2.3,4.1-4.1,4.1h-3v-8.1Zm0-12.5h2.9c1.8,0,3.9.5,3.9,3.6,0,1.9-1.1,3.5-4.1,3.5H63.7v-7.1Z" />
                        <path style={{ fill: fontColor }} d="M83.8,64.34h20.3v-6.5H92v-6.7h8.1v-6.3H92v-6.5h12.1V32H83.8Z" />
                        <path style={{ fill: fontColor }} d="M108.8,64.34h8.1v-14h2.7l4,14h8.1l-5.4-15.7a8.07,8.07,0,0,0,5.4-7.4c0-6.4-4.7-9.1-10.8-9.1H108.8Zm8.1-26.2h1.3c2.7,0,5.3.3,5.3,3.6,0,2.6-2,3.5-5.1,3.5h-1.6l.1-7.1Z" />
                        <path style={{ fill: fontColor }} d="M134.5,48.24c0,8.2,3,16.7,12.5,16.7s12.5-8.5,12.5-16.7-3-16.7-12.5-16.7S134.5,40,134.5,48.24Zm8.8,0c0-6.4,0-10.8,3.8-10.8s3.8,4.4,3.8,10.8,0,10.8-3.8,10.8S143.3,54.64,143.3,48.24Z" />
                    </g>
                    <path style={{ fill: 'hsl(10.1 82.54% 49.41%)' }} d="M3.2,26.1S19.7,0,52.4,0s49.5,26.1,49.5,26.1H83.7a46.47,46.47,0,0,0-62.5,0Z" />
                    <g>
                        <path style={{ fill: 'hsl(200.66 78.54% 45.69%)' }} d="M57.57,26.1h18.1s.5-.5,1.4-1.3a44,44,0,0,0-13.4-6.1A49,49,0,0,0,57.57,26.1Z" />
                        <path style={{ fill: 'hsl(200.66 78.54% 45.69%)' }} d="M106.77,0a58.6,58.6,0,0,0-23.7,5,57.5,57.5,0,0,1,8.3,5.6,62.57,62.57,0,0,1,5.2,4.6,43.67,43.67,0,0,1,10.2-1.1,46.48,46.48,0,0,1,31.3,12.1h18.2C156.27,26.1,139.47,0,106.77,0Z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}